<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.name"
    class="px-5 py-3"
    v-if="detail"
  >
    <base-detail-action-button
      :permissions="permissions"
      :basePath="basePath"
      :id="detail.uuid"
      v-if="detail.uuid"
      @deleteAction="deleteAction"
    >
    </base-detail-action-button>
    <v-row>
      <v-col cols="6" class="border--right">
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.name') }}
          </v-col>
          <v-col cols="6">
            {{ detail.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.electricityCapacity') }}
          </v-col>
          <v-col cols="6">
            {{ detail.electricity_capacity }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.furnishingCondition') }}
          </v-col>
          <v-col cols="6">
            {{ detail.furnishing_status }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.minBathroom') }}
          </v-col>
          <v-col cols="6">
            {{ detail.min_bathroom }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.maxBathroom') }}
          </v-col>
          <v-col cols="6">
            {{ detail.max_bathroom }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.minBedroom') }}
          </v-col>
          <v-col cols="6">
            {{ detail.min_bedroom }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.maxBedroom') }}
          </v-col>
          <v-col cols="6">
            {{ detail.max_bedroom }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.minBuildingSize') }}
          </v-col>
          <v-col cols="6">
            {{ detail.min_building_size }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.maxBuildingSize') }}
          </v-col>
          <v-col cols="6">
            {{ detail.max_building_size }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.minLandSize') }}
          </v-col>
          <v-col cols="6">
            {{ detail.min_land_size }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.maxLandSize') }}
          </v-col>
          <v-col cols="6">
            {{ detail.max_land_size }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.minPrice') }}
          </v-col>
          <v-col cols="6">
            {{ $n(detail.min_price, 'currency', 'id-ID') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.maxPrice') }}
          </v-col>
          <v-col cols="6">
            {{ $n(detail.max_price, 'currency', 'id-ID') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.unitType.totalFloor') }}
          </v-col>
          <v-col cols="6">
            {{ detail.total_floor }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('project.updatedBy') }}
          </v-col>
          <v-col cols="6">
            {{ detail.updated_by }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['detail', 'permissions'],
  computed: {
    ...mapState({
      basePath: (state) => state.unitType.basePath,
    }),
  },
  methods: {
    async deleteAction(uuid) {
      try {
        await this.$store.dispatch('unitType/delete', uuid);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('unitType.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
